import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Main from 'components/page/Main';
import SiteMeta from 'components/blog/SiteMeta';
import COLORS from 'constants/colors';

const Heading = styled.h2`
  color: ${COLORS.DARK_JUNGLE_GREEN} !important;
`;

const Content = styled.article`
  display: flex;
  margin: 2rem 0;
  text-decoration: none;
`;

const Excerpt = styled(Link)`
  color: ${COLORS.DARK_JUNGLE_GREEN} !important;
  padding-left: 2rem;
`;

function App({ data }) {
  const { edges } = data.allMdx;
  return (
    <Main>
      <div>
        <SiteMeta />
        {edges.map(edge => {
          const {
            frontmatter: { featuredImage, path, title, excerpt },
          } = edge.node;
          return (
            <div key={path} style={{ marginBottom: '1rem' }}>
              <Link to={path}>
                <Heading>{title}</Heading>
              </Link>
              <Content>
                <Link to={path}>
                  <img
                    src={featuredImage.childImageSharp.fixed.src}
                    alt={title}
                  />
                </Link>
                <Excerpt to={path}>
                  <p>{excerpt}</p>
                </Excerpt>
              </Content>
            </div>
          );
        })}
        <div>
          <Link to="/tags">Browse by Tag</Link>
        </div>
      </div>
    </Main>
  );
}

export const query = graphql`
  query HomepageQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
            title
            path
            date
            excerpt
          }
        }
      }
    }
  }
`;

export default App;
